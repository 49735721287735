<template>
  <nav
    id="nav-bar"
    class="bg-primary border-gray-200 dark:bg-gray-900 shadow-xl z-50 fixed w-full"
  >
    <div
      class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4"
    >
      <router-link
        :to="'/'"
        class="flex items-center space-x-3 rtl:space-x-reverse drop-shadow"
      >
        🍕
        <span
          class="self-center text-2xl font-extrabold whitespace-nowrap text-contrast_one hover:text-secondary"
          >Foodie Haven</span
        >
      </router-link>

      <!-- Add menu and order now router links -->
      <div class="flex items-center space-x-4">
        <router-link
          :to="'/'"
          class="text-contrast_one hover:text-secondary md:text-xl font-extrabold whitespace-nowrap"
        >
          Home
        </router-link>
        <router-link
          :to="'/order'"
          class="text-contrast_one hover:text-secondary md:text-xl font-extrabold whitespace-nowrap"
        >
          Order Now
        </router-link>
        <router-link
          :to="'/track'"
          class="text-contrast_one hover:text-secondary md:text-xl font-extrabold whitespace-nowrap"
        >
          Track
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style></style>
