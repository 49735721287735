import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store";

// Set default base URL for Axios
axios.defaults.baseURL =
  "https://food-ordering-platform-94009b3ecade.herokuapp.com/";

// Create Vue app instance
const app = createApp(App);

// Set Axios as a global property
app.config.globalProperties.$axios = axios;

// Use the router
app.use(store).use(router);

// Mount the app
app.mount("#app");
