import { createRouter, createWebHistory } from "vue-router";

// Import your components here
import HomePage from "../views/HomePage.vue";
import MenuPage from "../views/MenuPage.vue";
import OrderPage from "../views/OrderPage.vue";
import CheckoutPage from "../views/CheckoutPage.vue";
import SuccessPage from "../views/SuccessPage.vue";
import TrackingPage from "../views/TrackingPage.vue";

const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/menu/:slug",
    component: MenuPage,
    props: true,
  },
  {
    path: "/order",
    component: OrderPage,
  },
  {
    path: "/checkout",
    component: CheckoutPage,
  },
  {
    path: "/success",
    component: SuccessPage,
  },
  {
    path: "/track",
    component: TrackingPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
