<template>
  <HeroBanner />
  <LatestMenuItems />
  <MenusList />
</template>
<script>
import LatestMenuItems from "../components/LatestMenuItems.vue";
import HeroBanner from "../components/HeroBanner.vue";
import MenusList from "../components/MenusList.vue";

export default {
  name: "HomePage",
  components: {
    LatestMenuItems,
    HeroBanner,
    MenusList,
  },
  mounted() {
    document.title = "Foodie Haven | Home";
  },
};
</script>
