<template>
  <div v-if="acceptingOrders">
    <SectionHeader msg="Order Now" />
    <div
      class="flex flex-col-reverse xl:flex-row bg-contrast_one rounded shadow-xl p-4"
    >
      <div class="w-full xl:w-2/4 md:p-4">
        <SectionSubHeader msg="Menu" />
        <div
          v-for="menuItem in menuItems"
          :key="menuItem.id"
          class="flex w-full mb-4 bg-[#f0f0f0] rounded shadow-xl"
        >
          <img
            class="w-1/5 object-cover rounded-bl rounded-tl"
            :src="
              menuItem.image
                ? menuItem.get_image
                : 'http://food-ordering-platform-94009b3ecade.herokuapp.com/media/placeholder.jpg'
            "
            :alt="menuItem.name + ' Thumbnail'"
          />
          <div class="w-4/5 flex-col p-4">
            <div>{{ menuItem.name }} ({{ menuItem.calories }} cal)</div>
            <p class="italic text-xs mb-4">{{ menuItem.description }}</p>
            <div class="flex">
              <div class="w-1/2">${{ menuItem.price }}</div>
              <div class="w-1/2 text-right">
                <a class="custom-red-button" @click="addToCart(menuItem)"
                  >Add to Cart</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full xl:w-2/4 md:p-4">
        <div class="w-full flex">
          <div class="w-3/4"><SectionSubHeader msg="Cart" /></div>
          <div class="w-1/4 flex items-center justify-end">
            <router-link
              v-if="cartItems.length !== 0"
              class="custom-red-button"
              :to="'/checkout'"
            >
              Checkout
            </router-link>
          </div>
        </div>
        <div class="bg-[#f0f0f0] rounded shadow-xl p-4">
          <div v-if="cartItems.length === 0">
            <span>Your cart is empty.</span>
          </div>
          <div v-else>
            <div class="overflow-x-auto max-w-[300px] sm:max-w-none">
              <table class="w-full divide-y divide-gray-200">
                <thead>
                  <tr class="bg-gray-50">
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Item
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Note
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-contrast_one divide-y divide-gray-200">
                  <tr
                    v-for="cartItem in cartItems"
                    :key="cartItem.product.id"
                    class="hover:bg-gray-100"
                  >
                    <td class="px-6 py-4 whitespace-nowrap">
                      {{ cartItem.product.name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      ${{ getCartItemPrice(cartItem.product.slug) }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <input
                        type="number"
                        v-model="cartItem.quantity"
                        min="1"
                        class="w-16 px-3 py-2 text-sm leading-tight text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                        @change="updateCartItemQuantity(cartItem)"
                      />
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      ${{ getItemTotal(cartItem).toFixed(2) }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <input
                        type="text"
                        v-model="cartItem.note"
                        min="1"
                        class="w-16 px-3 py-2 text-sm leading-tight text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                        @change="setItemNote(cartItem)"
                      />
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <a
                        class="cursor-pointer"
                        @click="removeItemFromCart(cartItem)"
                        >🗑️</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="flex-col text-center mt-4">
              {{ cartTotalLength }} Total Item(s) | Total Price:
              {{ cartTotalPrice }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p class="mt-4">Sorry, we are not taking any new orders at the moment.</p>
  </div>
</template>

<script>
import axios from "axios";
import SectionHeader from "../components/SectionHeader.vue";
import SectionSubHeader from "../components/SectionSubHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "OrderPage",
  components: {
    SectionHeader,
    SectionSubHeader,
  },
  data() {
    return {
      acceptingOrders: false,
      cart: {
        items: [],
      },
      menuItems: null,
    };
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
  },
  methods: {
    getAcceptingOrders() {
      axios
        .get("/api/v1/settings/acceptingOrders/")
        .then((response) => {
          this.acceptingOrders = response.data === "Yes";
          if (!this.acceptingOrders) {
            return;
          }
          this.getMenuItems();
        })
        .catch((error) => {
          console.error("Error fetching accepting orders status:", error);
        });
    },
    getItemTotal(item) {
      return item.quantity * item.product.price;
    },
    getMenuItems() {
      axios
        .get("/api/v1/menu-items/")
        .then((response) => {
          this.menuItems = response.data;
        })
        .catch((error) => console.log(error));
    },
    getCartItemPrice(menuItemSlug) {
      if (!this.menuItems) {
        return null;
      }

      const menuItem = this.menuItems.find(
        (item) => item.slug === menuItemSlug
      );
      if (menuItem) {
        return menuItem.price.toLocaleString("en-CA", {
          style: "currency",
          currency: "CAD",
        });
      } else {
        return null;
      }
    },
    addToCart(menuItem) {
      const item = {
        product: menuItem,
        quantity: 1,
      };
      this.$store.commit("addToCart", item);
    },
    updateCartItemQuantity(cartItem) {
      this.$store.dispatch("updateCartItemQuantity", cartItem);
    },
    removeItemFromCart(cartItem) {
      this.$store.dispatch("removeItemFromCart", cartItem);
    },
    setItemNote(cartItem) {
      this.$store.dispatch("setItemNote", cartItem);
    },
  },
  mounted() {
    this.getAcceptingOrders();
    this.getMenuItems();
    this.cart = this.$store.state.cart;

    document.title = "Foodie Haven | Order Now";
  },
  computed: {
    ...mapGetters(["cartItems"]),
    cartTotalLength() {
      const items = this.$store.state.cart.items;
      if (!items || !Array.isArray(items)) {
        return 0;
      }
      return items.reduce((total, item) => total + item.quantity, 0);
    },
    cartTotalPrice() {
      const items = this.$store.state.cart.items;

      let totalPrice = 0;

      items.forEach((cartItem) => {
        const price = this.getCartItemPrice(cartItem.product.slug);
        if (price && cartItem.quantity > 0) {
          totalPrice += price * cartItem.quantity;
        }
      });

      return totalPrice.toLocaleString("en-CA", {
        style: "currency",
        currency: "CAD",
      });
    },
  },
};
</script>
