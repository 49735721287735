import { createStore } from "vuex";

export default createStore({
  state: {
    cart: {
      items: [],
    },
    isAuthenticated: false,
    token: "",
    isLoading: false,
  },
  mutations: {
    initializeStore(state) {
      const storedCart = localStorage.getItem("cart");
      if (storedCart) {
        state.cart = JSON.parse(storedCart);
      } else {
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
      // Ensure that state.cart.items is always initialized
      if (!state.cart.items) {
        state.cart.items = [];
      }
    },
    addToCart(state, item) {
      const exists = state.cart.items
        ? state.cart.items.filter((i) => i.product.id === item.product.id)
        : [];

      if (exists.length) {
        exists[0].quantity =
          parseInt(exists[0].quantity) + parseInt(item.quantity);
      } else {
        state.cart.items.push(item);
      }

      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    updateCartItemQuantity(state, payload) {
      const id = payload.product.id;
      const quantity = payload.quantity;
      const cartItem = state.cart.items.find((item) => item.product.id === id);
      if (cartItem) {
        if (quantity === 0) {
          state.cart.items = state.cart.items.filter(
            (item) => item.product.id !== id
          );
        } else {
          cartItem.quantity = quantity;
        }
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },
    removeItemFromCart(state, payload) {
      const id = payload.product.id;
      const cartItem = state.cart.items.find((item) => item.product.id === id);
      if (cartItem) {
        state.cart.items = state.cart.items.filter(
          (item) => item.product.id !== id
        );
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },
    setItemNote(state, payload) {
      const id = payload.product.id;
      const note = payload.note;
      const cartItem = state.cart.items.find((item) => item.product.id === id);
      if (cartItem) {
        cartItem.note = note;
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      state.token = "";
      state.isAuthenticated = false;
    },
    clearCart(state) {
      state.cart = { items: [] };

      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
  },
  actions: {
    updateCartItemQuantity({ commit }, payload) {
      commit("updateCartItemQuantity", payload);
    },
    removeItemFromCart({ commit }, payload) {
      commit("removeItemFromCart", payload);
    },
    setItemNote({ commit }, payload) {
      commit("setItemNote", payload);
    },
  },
  modules: {},
  getters: {
    cartItems: (state) => state.cart.items,
  },
});
