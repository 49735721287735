<template>
  <SectionHeader msg="Thank you!" />
  <span>{{ trackingCode }}</span>
</template>

<script>
import SectionHeader from "../components/SectionHeader.vue";

export default {
  name: "SuccessPage",
  components: {
    SectionHeader,
  },
  data() {
    return {
      trackingCode: "", // Corrected the declaration
    };
  },
  mounted() {
    document.title = "Foodie Haven | Order Now";
    this.trackingCode = this.$route.query.trackingCode;
  },
};
</script>
