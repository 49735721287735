<template>
  <SectionHeader msg="Order Tracking" class="text-center" />
  <div class="flex flex-col bg-contrast_one rounded shadow-xl p-4">
    <div class="w-full flex flex-col md:flex-row">
      <div class="w-full p-4" id="tracking-details">
        <SectionSubHeader msg="Details" class="text-center md:text-left" />
        <div class="" v-if="errors.length">
          <p
            v-for="error in errors"
            v-bind:key="error"
            class="font-semibold text-primary"
          >
            {{ error }}
          </p>
        </div>
        <div class="mt-4">
          <label
            for="tracking_code"
            class="block text-sm font-medium text-gray-700"
            >Tracking Code</label
          >
          <input
            v-model="tracking_code"
            type="text"
            name="tracking_code"
            id="tracking_code"
            required
            class="mt-1 h-8 focus:ring-primary focus:border-primary block w-full shadow-xl sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="mt-4">
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email</label
          >
          <input
            v-model="email"
            type="email"
            name="email"
            id="email"
            required
            class="mt-1 h-8 focus:ring-primary focus:border-primary block w-full shadow-xl sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <button class="mt-8 custom-red-button" @click="submitForm">
          Track Order
        </button>
      </div>
      <div id="tracking-result-container" class="hidden w-full p-4">
        <SectionSubHeader msg="Status" class="text-center md:text-left" />
        <div id="tracking-result" class="hidden flex-col">
          <div id="status-received-container" class="flex mt-4">
            <div class="items-center justify-center flex mr-4">
              <div
                id="status-received"
                class="w-[25px] h-[25px] rounded-xl bg-[#08A045]"
              ></div>
            </div>
            <div class="">Received</div>
          </div>
          <div id="status-preparing-container" class="flex mt-4">
            <div class="items-center justify-center flex mr-4">
              <div
                id="status-preparing"
                class="w-[25px] h-[25px] rounded-xl bg-[#08A045]"
              ></div>
            </div>
            <div class="">Preparing</div>
          </div>
          <div id="status-ready-container" class="flex mt-4">
            <div class="items-center justify-center flex mr-4">
              <div
                id="status-ready"
                class="w-[25px] h-[25px] rounded-xl bg-[#08A045]"
              ></div>
            </div>
            <div class="">Ready for Pickup!</div>
          </div>
          <div id="status-final-container" class="flex mt-4">
            <div class="items-center justify-center flex mr-4">
              <div
                id="status-final"
                class="w-[25px] h-[25px] rounded-xl bg-[#08A045]"
              ></div>
            </div>
            <div class="">
              <span id="status-collected" class="hidden">Collected!</span>
              <span id="status-cancelled" class="hidden">Cancelled</span>
              <span id="status-delayed" class="hidden">Delayed</span>
              <span id="status-refunded" class="hidden">Refunded</span>
            </div>
          </div>
          <div class="flex">
            <div class="w-1/3"></div>
          </div>
        </div>
        <button class="mt-8 custom-red-button" @click="resetForm">
          Track another order?
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import SectionHeader from "@/components/SectionHeader.vue";
import SectionSubHeader from "@/components/SectionSubHeader.vue";
import axios from "axios";

export default {
  name: "TrackingPage",
  components: { SectionHeader, SectionSubHeader },
  data() {
    return {
      tracking_code: "",
      email: "",
      errors: [],
    };
  },
  mounted() {
    this.email = this.$route.query.email;
    this.tracking_code = this.$route.query.tracking_code;
    if (this.email && this.tracking_code) {
      this.submitForm();
    }
  },
  methods: {
    resetForm() {
      this.tracking_code = "";
      this.email = "";
      this.errors = [];

      document.querySelector("#tracking-details").classList.remove("hidden");
      document
        .querySelector("#tracking-result-container")
        .classList.add("hidden");
    },
    submitForm() {
      const params = new URLSearchParams(window.location.search);
      if (this.email != undefined) {
        if (params.has("email")) {
          params.set("email", this.email);
        } else {
          params.append("email", this.email);
        }
      }
      if (this.tracking_code != undefined) {
        if (params.has("tracking_code")) {
          params.set("tracking_code", this.tracking_code);
        } else {
          params.append("tracking_code", this.tracking_code);
        }
      }

      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.pushState({}, "", newUrl);

      this.errors = [];
      if (
        typeof this.tracking_code === "undefined" ||
        this.tracking_code === ""
      ) {
        this.errors.push("The tracking code is missing!");
      }

      if (typeof this.email === "undefined" || this.email === "") {
        this.errors.push("The email address is missing!");
      } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)
      ) {
        this.errors.push(
          "Invalid email address format. Please enter a valid email address."
        );
      }

      if (!this.errors.length) {
        axios
          .get("/api/v1/track/", {
            params: {
              tracking_code: this.tracking_code,
              email: this.email,
            },
          })
          .then((response) => {
            const status = response.data.trackingResult
              .toLowerCase()
              .split(" ")[0];

            if (status === "dne") {
              this.errors.push("ERROR: Order not found.");

              document
                .querySelector("#tracking-result-container")
                .classList.add("hidden");
            } else {
              this.errors = "";
              document
                .querySelector("#tracking-result-container")
                .classList.remove("hidden");

              document
                .querySelector("#tracking-result")
                .classList.remove("hidden");

              document
                .querySelector("#tracking-details")
                .classList.add("hidden");

              document.querySelector("#tracking-result").classList.add("flex");

              if (
                status === "received" ||
                status === "preparing" ||
                status === "ready"
              ) {
                document
                  .querySelector("#status-" + status)
                  .classList.remove("bg-[#08A045]");

                document
                  .querySelector("#status-" + status)
                  .classList.add("bg-primary", "animate-pulse");

                document
                  .querySelector("#status-final-container")
                  .classList.add("hidden");
              } else {
                document
                  .querySelector("#status-final")
                  .classList.add("bg-primary", "animate-pulse");
                document
                  .querySelector("#status-" + status)
                  .classList.remove("hidden");

                document
                  .querySelector("#status-received-container")
                  .classList.add("hidden");

                document
                  .querySelector("#status-preparing-container")
                  .classList.add("hidden");

                document
                  .querySelector("#status-ready-container")
                  .classList.add("hidden");
              }

              // If Order Status is Received, do not show Preparing
              if (status == "received") {
                document
                  .querySelector("#status-preparing-container")
                  .classList.add("hidden");
              }
              if (status == "received" || status == "preparing") {
                document
                  .querySelector("#status-ready-container")
                  .classList.add("hidden");
              }
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error while fetching tracking data:", error);
            if (
              error.response &&
              error.response.status === 400 &&
              error.response.data
            ) {
              // Check if the response contains specific error message
              const errorMessage = error.response.data;
              if (errorMessage === "Invalid email or tracking code.") {
                this.errors.push(errorMessage);
              } else {
                this.errors.push(
                  "An unexpected error occurred. Please try again later."
                );
              }
            } else {
              this.errors.push(
                "An unexpected error occurred. Please try again later."
              );
            }
          });
      }
    },
  },
};
</script>
