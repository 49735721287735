<template>
  <h4
    id="{{ id }}"
    class="mt-4 mb-4 text-2xl md:text-3xl lg:text-4xl font-extrabold leading-none tracking-tight text-contrast_two text-left w-full drop-shadow"
  >
    {{ msg }}
  </h4>
</template>

<script>
export default {
  name: "SectionSubHeader",
  props: {
    msg: String,
  },
};
</script>

<style>
h4 {
  scroll-margin-top: 2em;
}
</style>
