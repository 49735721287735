<template>
  <router-link
    :to="'/menu/' + menu.slug"
    class="bg-primary text-contrast_one flex items-center justify-center h-[150px] lg:h-[200px] shadow-xl rounded-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 hover:bg-[#bf0f1e] duration-300"
  >
    <h3 class="text-4xl font-semibold">{{ menu.name }}</h3>
  </router-link>
</template>

<script>
export default {
  name: "MenuTile",
  props: {
    menu: Object,
  },
};
</script>
