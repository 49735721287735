<template>
  <div id="app">
    <NavBar />
    <div class="bg-secondary flex flex-col p-4 items-center">
      <div class="flex flex-col mt-16 max-w-screen-xl mb-4">
        <router-view />
      </div>
    </div>
    <FooterBar />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterBar from "./components/FooterBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterBar,
  },
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

/* In your CSS or SCSS file */
.custom-red-button {
  @apply bg-primary p-2 text-contrast_one cursor-pointer shadow-xl rounded-lg transition ease-in-out duration-300;

  /* Hover styles */
  @apply hover:-translate-y-1 hover:scale-105 hover:bg-[#bf0f1e];
}

.anchored {
  @apply text-[#bf0f1e] underline;
}
</style>
