<template>
  <div v-if="acceptingOrders">
    <SectionHeader msg="Checkout" />
    <div class="flex flex-col bg-contrast_one rounded shadow-xl p-4">
      <div v-if="cartItems.length === 0">
        <span>Your cart is empty.</span>
        <router-link :to="'order'"
          >&nbsp;<span class="text-primary font-semibold"
            >Feeling Hungry?</span
          ></router-link
        >
      </div>
      <div v-else class="w-full flex flex-col-reverse md:flex-row">
        <div class="w-full md:w-1/4 md:p-4 min-w-[300px]">
          <SectionSubHeader msg="User info" />
          <div class="" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error" class="text-red-500">
              {{ error }}
            </p>
          </div>
          <div class="mt-4">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Name</label
            >
            <input
              v-model="first_name"
              type="text"
              name="first_name"
              id="first_name"
              required
              class="mt-1 h-8 focus:ring-primary focus:border-primary block w-full shadow-xl sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="mt-4">
            <label for="phone" class="block text-sm font-medium text-gray-700"
              >Phone Number</label
            >
            <input
              v-model="phone"
              type="tel"
              name="phone"
              id="phone"
              required
              class="mt-1 h-8 focus:ring-primary focus:border-primary block w-full shadow-xl sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="mt-4">
            <label for="email" class="block text-sm font-medium text-gray-700"
              >Email</label
            >
            <input
              v-model="email"
              type="email"
              name="email"
              id="email"
              required
              class="mt-1 h-8 focus:ring-primary focus:border-primary block w-full shadow-xl sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="mt-4">
            <label for="note" class="block text-sm font-medium text-gray-700"
              >Note</label
            >
            <input
              v-model="note"
              type="note"
              name="note"
              id="note"
              class="mt-1 h-8 focus:ring-primary focus:border-primary block w-full shadow-xl sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div id="card-element" class="mt-8"></div>
          <button class="mt-8 custom-red-button" @click="submitForm">
            Pay with Stripe
          </button>
        </div>
        <div class="w-full md:w-3/4 md:p-4">
          <SectionSubHeader msg="Cart" />
          <div
            class="overflow-x-auto max-w-[400px] md:max-w-none sm:max-w-none"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr class="bg-gray-50">
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Item
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Note
                  </th>
                </tr>
              </thead>
              <tbody class="bg-contrast_one divide-y divide-gray-200">
                <tr
                  v-for="cartItem in cartItems"
                  :key="cartItem.product.id"
                  class="hover:bg-gray-100"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ cartItem.product.name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ getCartItemPrice(cartItem.product.slug) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ cartItem.quantity }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    ${{ getItemTotal(cartItem).toFixed(2) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ cartItem.note }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="flex-col text-center mt-4">
            {{ cartTotalLength }} Total Item(s) | Total Price: ${{
              cartTotalPrice.toFixed(2)
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p class="mt-4">Sorry, we are not taking any new orders at the moment.</p>
  </div>
</template>
<script>
import axios from "axios";
import SectionHeader from "../components/SectionHeader.vue";
import SectionSubHeader from "../components/SectionSubHeader.vue";
import { mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";

export default {
  name: "CheckoutPage",
  components: {
    SectionHeader,
    SectionSubHeader,
  },
  data() {
    return {
      acceptingOrders: false,
      cart: {
        items: [],
      },
      menuItems: null,
      stripe: {},
      card: {},
      first_name: "",
      email: "",
      phone: "",
      note: "",
      errors: [],
      stripeMounted: false,
    };
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
  },
  mounted() {
    this.getAcceptingOrders();
    this.getMenuItems();
    this.cart = this.$store.state.cart;
    document.title = "Foodie Haven | Order Now";
  },

  updated() {
    if (!this.stripeMounted) {
      this.stripeMounted = true;
      if (this.cartTotalLength > 0) {
        loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
          .then((stripe) => {
            const elements = stripe.elements();
            this.stripe = stripe;
            this.card = elements.create("card", { hidePostalCode: true });
            this.card.mount("#card-element");
          })
          .catch((error) => {
            console.error("Error loading Stripe:", error);
          });
      }
    }
  },

  methods: {
    getAcceptingOrders() {
      axios
        .get("/api/v1/settings/acceptingOrders/")
        .then((response) => {
          this.acceptingOrders = response.data === "Yes";
          if (!this.acceptingOrders) {
            return;
          }
          this.getMenuItems();
        })
        .catch((error) => {
          console.error("Error fetching accepting orders status:", error);
        });
    },
    getMenuItems() {
      axios
        .get("/api/v1/menu-items/")
        .then((response) => {
          this.menuItems = response.data;
        })
        .catch((error) => console.log(error));
    },
    getItemTotal(item) {
      return item.quantity * item.product.price;
    },
    getCartItemPrice(menuItemSlug) {
      if (!this.menuItems) {
        return null;
      }

      const menuItem = this.menuItems.find(
        (item) => item.slug === menuItemSlug
      );

      if (menuItem) {
        return menuItem.price.toLocaleString("en-CA", {
          style: "currency",
          currency: "CAD",
        });
      } else {
        return null;
      }
    },
    submitForm() {
      this.errors = [];
      if (this.first_name === "") {
        this.errors.push("The name field is missing!");
      }

      if (this.email === "") {
        this.errors.push("The email field is missing!");
      } else if (!/\S+@\S+\.\S+/.test(this.email)) {
        this.errors.push(
          "Invalid email address format. Please enter a valid email address."
        );
      }
      if (this.phone === "") {
        this.errors.push("The phone field is missing!");
      } else if (!/^\d{10}$/.test(this.phone)) {
        this.errors.push(
          "Invalid phone number format. Please use the format: 5141234567"
        );
      }

      if (!this.errors.length) {
        this.$store.commit("setIsLoading", true);

        this.stripe.createToken(this.card).then((result) => {
          if (result.error) {
            this.$store.commit("setIsLoading", false);

            this.errors.push(
              "Something went wrong with Stripe. Please try again"
            );

            console.log(result.error.message);
          } else {
            this.stripeTokenHandler(result.token);
          }
        });
      }
    },
    async stripeTokenHandler(token) {
      const items = [];

      for (let i = 0; i < this.cart.items.length; i++) {
        const item = this.cart.items[i];
        const obj = {
          menuItem: item.product.id,
          quantity: item.quantity,
          price: item.product.price,
          note: item.note
        };

        items.push(obj);
      }

      const data = {
        first_name: this.first_name,
        email: this.email,
        phone: this.phone,
        items: items,
        stripe_token: token.id,
        note: this.note,
      };

      await axios
        .post("/api/v1/checkout/", data)
        .then((response) => {
          const trackingCode = response.data.tracking_code;
          this.$store.commit("clearCart");
          this.$router.push({ path: "/success", query: { trackingCode } });
        })
        .catch((error) => {
          this.errors.push("Something went wrong. Please try again");
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
  computed: {
    ...mapGetters(["cartItems"]),
    cartTotalLength() {
      const items = this.$store.state.cart.items;
      if (!items || !Array.isArray(items)) {
        return 0;
      }
      return items.reduce((total, item) => total + item.quantity, 0);
    },
    cartTotalPrice() {
      return this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.product.price * curVal.quantity);
      }, 0);
    },
  },
};
</script>
