<template>
  <div id="menu-detail">
    <div v-if="menu && menu.menu_sections" class="flex flex-col">
      <!-- Render menu sections -->
      <div
        class="md:fixed md:top-12 md:left-0 md:bg-secondary z-10 md:shadow-xl md:w-full md:flex md:justify-center"
      >
        <div class="mt-28 md:mt-12 md:ml-4 md:mr-4 max-w-screen-xl">
          <SectionHeader :msg="menu.menu.name" />
          <p class="mt-4 mb-4 italic">{{ menu.menu.description }}</p>
        </div>
      </div>
      <div class="md:mt-44 flex flex-col md:flex-row menu-sections">
        <div class="w-full static md:w-1/3 flex flex-col">
          <div
            class="fixed p-4 top-0 left-0 right-0 mt-16 md:mt-0 bg-white z-10 md:bg-transparent md:top-auto md:left-auto md:right-auto"
          >
            <h3
              class="mb-2 md:mt-0 md:mb-0 text-2xl md:text-3xl lg:text-4xl font-extrabold leading-none tracking-tight text-contrast_two drop-shadow"
            >
              Sections
            </h3>
            <a
              v-for="section in menu.menu_sections"
              :key="section.id"
              :href="'#' + section.section.name.replace(/\s/g, '')"
              :id="'anchor-' + section.section.name.replace(/\s/g, '')"
              :class="[
                'anchor mt-4 mb-4 mr-4 md:mr-0 md:mb-0 md:flex text-xl md:text-2xl lg:text-3xl font-extrabold leading-none tracking-tight text-[#db1020] drop-shadow transition ease-in-out delay-150 hover:text-[#bf0f1e]',
                {
                  anchored:
                    this.anchor === section.section.name.replace(/\s/g, ''),
                },
              ]"
            >
              {{ section.section.name }}
            </a>
          </div>
        </div>
        <div class="w-full md:w-2/3">
          <div v-for="section in menu.menu_sections" :key="section.id">
            <SectionSubHeader
              :id="section.section.name.replace(/\s/g, '')"
              :msg="section.section.name"
            />
            <p class="mt-4 mb-4 italic">{{ section.section.description }}</p>
            <div class="grid grid-cols-2 gap-4 justify-center">
              <MenuItem
                v-for="menuItem in section.items"
                :key="menuItem.id"
                :menu-item="menuItem"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
h4 {
  scroll-margin-top: 8em;
}

/* For screens with a width of 768px or larger (medium-sized screens) and above */
@media screen and (min-width: 768px) {
  h4 {
    scroll-margin-top: 12.75em;
  }
}

/* For screens with a width of 1200px or larger (extra-large screens) and above */
@media screen and (min-width: 1200px) {
  h4 {
    scroll-margin-top: 9em;
  }
}
</style>

<script>
import axios from "axios";
import MenuItem from "../components/MenuItem.vue";
import SectionHeader from "../components/SectionHeader.vue";
import SectionSubHeader from "../components/SectionSubHeader.vue";

export default {
  name: "MenuPage",
  components: {
    MenuItem,
    SectionHeader,
    SectionSubHeader,
  },
  data() {
    return {
      menu: null,
      anchor: "",
    };
  },
  mounted() {
    this.getMenuDetail();
    const menuSlug = this.$route.params.slug;

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    document.title = `Foodie Haven | ${capitalizeFirstLetter(menuSlug)} Menu`;

    if (this.$route.hash) {
      this.anchor = this.$route.hash.substring(1);
    }
  },
  watch: {
    "$route.hash": {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          var anchor = newVal.substring(1);
          this.anchorElements = document.getElementsByClassName("anchor");
          for (let element of this.anchorElements) {
            element.classList.remove("anchored");
          }
          this.anchorElement = document.getElementById("anchor-" + anchor);
          if (this.anchorElement) {
            this.anchorElement.classList.add("anchored");
          }
        } else {
          this.anchorElement = null;
        }
      },
    },
  },
  methods: {
    getMenuDetail() {
      const menuSlug = this.$route.params.slug;
      axios
        .get(`/api/v1/menu/${menuSlug}`)
        .then((response) => {
          this.menu = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
