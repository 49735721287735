<template>
  <SectionHeader msg="Menus" />
  <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
    <MenuTile v-for="menu in menusList" :key="menu.id" :menu="menu" />
  </div>
</template>

<script>
import axios from "axios";
import SectionHeader from "../components/SectionHeader.vue";
import MenuTile from "../components/MenuTile.vue";

export default {
  name: "MenusList",
  components: {
    SectionHeader,
    MenuTile,
  },
  data() {
    return {
      menusList: [],
    };
  },
  mounted() {
    this.getMenusList();
  },
  methods: {
    getMenusList() {
      axios
        .get("/api/v1/menus/")
        .then((response) => {
          this.menusList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
