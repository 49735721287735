<template>
  <footer class="bg-primary text-contrast_one text-center py-4 font-extrabold">
    <p>Made by <a href="https://gowriekaran.com" class="underline">K7</a></p>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
};
</script>

<style></style>
