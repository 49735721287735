<template>
  <div class="bg-contrast_one rounded-lg shadow-xl overflow-hidden">
    <img
      class="w-full h-48 sm:h-32 object-cover"
      :src="
        menuItem.image
          ? menuItem.get_image
          : 'http://food-ordering-platform-94009b3ecade.herokuapp.com/media/placeholder.jpg'
      "
      :alt="menuItem.name + ' Thumbnail'"
    />
    <div class="p-4">
      <div class="flex-col md:flex-row flex w-full">
        <h3 class="lg:w-2/3 text-xl font-semibold text-gray-800">
          {{ menuItem.name }}
        </h3>
        <span
          class="lg:w-1/3 md:ml-1 md:items-center md:flex lg:ml-0 text-sm text-left lg:text-right"
          >({{ menuItem.calories }} cal)</span
        >
      </div>
      <p class="mt-2 text-gray-600 italic text-sm">
        {{ menuItem.description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    menuItem: Object,
  },
};
</script>
