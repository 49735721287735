<template>
  <h2
    class="mt-4 mb-4 text-4xl font-extrabold leading-none tracking-tight text-contrast_two md:text-5xl lg:text-6xl text-left w-full drop-shadow"
  >
    {{ msg }}
  </h2>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    msg: String,
  },
};
</script>

<style></style>
