<template>
  <div
    class="mt-6 mb-4 bg-[url('./assets/background_food.jpg')] bg-cover bg-center w-full min-h-[200px] flex justify-center items-center shadow-xl"
  >
    <h1
      class="text-4xl md:text-8xl lg:text-9xl font-extrabold leading-none tracking-tight text-[#db1020] drop-shadow"
    >
      Foodie Haven
    </h1>
  </div>
</template>
<script>
export default {
  name: "HeroBanner",
};
</script>
