<template>
  <SectionHeader msg="Newest Delights!" />
  <div class="grid grid-cols-2 lg:grid-cols-4 gap-4">
    <MenuItem
      v-for="menuItem in latestMenuItems"
      :key="menuItem.id"
      :menu-item="menuItem"
    />
  </div>
</template>

<script>
import axios from "axios";
import SectionHeader from "./SectionHeader.vue";
import MenuItem from "./MenuItem.vue";

export default {
  name: "LatestMenuItems",
  components: {
    SectionHeader,
    MenuItem,
  },
  data() {
    return {
      latestMenuItems: [],
    };
  },
  mounted() {
    this.getLatestMenuItems();
  },
  methods: {
    getLatestMenuItems() {
      axios
        .get("/api/v1/latest-menu-items/")
        .then((response) => {
          this.latestMenuItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
